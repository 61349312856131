.App {
  min-height: 100vh;
  max-width: 100%;
  flex-wrap: wrap;
  display: flex;
  overflow-wrap: anywhere;
  color: #4b4b4b;
}

h1 {
  text-align: start;
}

.App-link {
  color: #61dafb;
}

.chart-container {
  width: 800px;
  padding: 12px;
}

.selector-container {
  display: flex;
  width: 500px;
  flex-direction: row;
  height: 50px;
  gap: 16px;
}

.apikey-selector {
  padding: 8px;
}
.yearmonth-selector {
  padding: 8px;
}

.incident-container {
  margin: 24px;
  padding: 20px;
}

.datetime {
  font-size: 24px;
  color: #3d5466;
}

.item-container {
  margin-bottom: 40px;
}

.endpoint {
  font-size: 14px;
}

b {
  color: #3d5466;
}
